@import "~monday-ui-style/dist/index.min.css";
@import "~monday-ui-react-core/src/styles/themes.scss";

.App {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
