@import "~monday-ui-style/dist/index.min.css";
@import "~monday-ui-react-core/src/styles/themes.scss";

.start-screen {
  @include theme-prop;

  align-self: flex-start;

  &__title {
    text-align: center;
  }

  &__requirement-message {
    font-size: 2rem;
  }

  &__column-list {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__dropdowns {
    width: 275px;
  }

  &__dropdown {
    width: 100%;
  }

  &__column-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__buid-btn-container {
    margin-top: 24px;

    .build-button {
      display: flex;
      justify-content: center;

      &__btn {
        width: 200px;
        padding: 8px 0;
      }
    }
  }

  .mapping-dropdown {
    &-container {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }

    &-container:first-child {
      margin-top: 0;
    }

    &__dropdown {
      margin-left: 10px;
      flex: 1;
    }

    &__title {
      flex-basis: 70px;
    }
  }
}
