@import "~monday-ui-style/dist/index.min.css";
@import "~monday-ui-react-core/src/styles/themes.scss";

.map {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: space-between;
}

.btn-group {
  flex-direction: row !important;
}

.monday-style-button-group-component__buttons-container {
  flex-direction: column;
  flex: 1;

  .monday-style-button {
    flex: 1;
    width: 100%;
  }
}
